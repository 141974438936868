@import "@optimuminterfaces/revex-react-components/dist/_mixins";
@import "@optimuminterfaces/revex-react-components/dist/_colors";

.time-ordering-rt {
	@include clearfix();
	&.time-ordering-horizontal-rt {
		.time-ordering-item {
			float: left;
		}
	}
	:global(.drag-drop-item) {
		&:hover {
			background-color: transparent;
		}
		:global(.drag-drop-item-container) {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: transparent;
			:global(.drag-drop-icon) {
				// margin-right: 10px;
			}
		}
	}

	.time-ordering-item {
		@include borderRadius(10px);
		text-align: center;
		margin-right: 10px;
		position: relative;

		// >div{
		// 	float: left;

		// 	:global(.input-rt){
		// 		margin-bottom: 0px;
		// 		line-height: 40px;
		// 	}
		// }
		.dates {
			padding: 5px;
			min-width: 115px;
			font-size: 10px;
			text-align: left;
			background-color: $primary-color;
			color: #ffffff;
			min-height: 35px;
			
			&.one-date{
				display: flex;
				align-items: center;
			}
		}
		.details {
			@include clearfix();
			padding: 5px;
			background-color: $secondary-color-light;
			display: flex;
			align-items: center;
			justify-content: center;
			> div {
				float: left;

				:global(.input-rt) {
					margin-bottom: 0px;
					line-height: 40px;
				}
			}

			.time-ordering-time {
				// line-height: 40px;
			}
		}

		&:hover {
			.actions {
				height: 35px;
			}
		}

		.actions {
			@include transition(all, 0.5s);
			overflow: hidden;
			position: absolute;
			top: 0;
			height: 0px;
			width: 116px;
			background-color: rgba(0, 0, 0, 0.6);
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
			

			.time-ordering-action {
				button {
					font-size: 14px;
					// gap: 5px
					background-color: transparent !important;
					&:hover{
						color: $primary-color;
					}
				}
			}
			.time-reference-date-action {

				button {
					font-size: 16px;
					&:hover{
						color: $primary-color;
					}
					margin-right: 5px;

				}
			}
		}
	}
}
