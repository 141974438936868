@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.point-incident-list-rt{
	
	:global(.message), :global(.table-rt){
		font-size: 12px;
	}
	.actions{
		@include clearfix();
		margin-bottom: 10px;
		.action{
			float: right;
			margin-right: 10px;
		}
	}
	.modal-detail{
		dl{
			@include clearfix();
			dt{
				float: left;
				clear: left;
				font-weight: bold;
				margin-right: 10px;
				//line-height: 22px;
				margin-bottom: 10px;
				width: 150px;
			}

			dd{
				float: left;
				margin-left: 0px;
				//line-height: 22px;
				text-align: left;
				padding-right: 20px;
				margin-bottom: 10px;
			}
		}

		.dd-list-edit-points{
			display: inline-flex;

			.list-points{
				vertical-align: middle;
				display: inline-flex;
				>*{
					margin-top: auto;
					margin-bottom: auto;
				}
			}

			.add-point-action{
				display: inline-flex;
				vertical-align: middle;
			}

			.times-middle, button{
				margin-top: auto;
				margin-bottom: auto;
			}
		}

		.modal-detail-actions{
			text-align: center;
			margin-top: 10px;

			.modal-detail-action{
				margin-right: 10px;
			}
		}
	}
}