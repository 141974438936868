@import '@optimuminterfaces/revex-react-components/dist/_colors';
@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.point-registration-event-new{
    @include componentDefaultFonts();
    .additional-data{
        line-height: 30px;
        margin-bottom: 20px;
        span{
            font-weight: bold;
        }
        .overtime{
            color: $green-color-light;
        }
        .late{
            color: $red-color-light;
        }

        :global(.avatar-name-text){
            margin-top: 0px
        }
        
    }
    .resume{
        @include borderRadius(10px);
        min-height: 80px;
        background-color: #f7f7f7;
        padding: 10px;
        
    }

    :global(.datetime-picker-rt ){
        label{
            line-height: 20px;
        }
    }

    dt{
        float: left;
        clear: left;
        font-weight: bold;
        padding-right: 1%;
        line-height: 28px;
        width: 200px;
    }

    dd{
        margin-left: 0px;
        line-height: 28px;
        //text-align: right;
        padding-right: 2%;

    }
	.point-event-footer{
		text-align: center;
	}
    
}