@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.export-file-rt{
    @include clearfix();

    .dual-list-rt{
        margin-top: 15px;
    }

    .text-information-rt{
        margin: 50px;
    }

    .loading-rt{
        margin: 50px;
    }

    .btn-generate-file{
        text-align: right;
        margin-top: 15px;
    }
}