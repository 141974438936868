.new-split-hour{
    .form-new-split-hour{
        .action-split-hour{
            text-align: center;
			button{
				&:first-child{
					margin-right: 10px;
				}

			}
        }
    }
}