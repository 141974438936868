@import '@optimuminterfaces/revex-react-components/dist/_colors';
@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.point-registration-my-new-rt{
	
	.point-registration-details{
		@include borderRadiusBottomLeft(10px);
		@include borderRadiusBottomRight(10px);
		background-color: $primary-color;
		color: #ffffff;
		text-align: center;
	
		.point-registration-avatar{
			position: absolute;
			margin-top: -50px;
			z-index: 2;
			left: 50%;
			margin-left: -45px;

		}
		.point-registration-employee{
			font-weight: bold;
			padding-top: 50px;
		}
		.point-registration-planned{
			margin-top: 10px;
		}
		.point-registration-clock{
			@include clearfix();
			margin-top: 10px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}
		.point-registration-action{
			padding: 20px;

			.button-register{
				background-color: #ffffff;
				color: $primary-color;

				&:hover{
					background-color: #dadff8;
				}

			}
		}
	}

	.point-registration-last-registers{
		@include clearfix();
		.points-last{
			>div{
				float: left;
				margin-right: 10px;
			}
		}

	}

	.modal-new-point{
		text-align: center;
		.modal-point-registration-action{
			
		}
	}
	
}