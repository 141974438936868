.page-point-new-rt{
    .form-point-new{
        .datetime-picker-content{
            z-index: 11;
        }

        .copy-srep-configuration{
            display: flex;
            margin-top: 15px;
            margin-bottom: 10px;

            .switch-rt {
                font-size: 14px;
            }
        }

        .form-point-new-actions{
            text-align: center;
            margin-top: 10px;

            .btn-rt{
				margin-top: 5px;
                &:first-child{
                    margin-right: 10px;
                }
            }
        }
    }
}