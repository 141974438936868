.report-new-rt{
	.actions{
		text-align: center;
		margin-top: 20px;
		:global(.btn-rt){
			&:first-of-type{
				margin-right: 10px;
			}
		}
	}
}