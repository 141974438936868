
@import '@optimuminterfaces/revex-react-components/dist/_colors';
@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.closed-period-list-rt{

	@include componentDefaultFonts();

	.group-buttons{
        float: right;
    }
    
    .modal-delete{
        text-align: center;
		.modal-delete-action{
			margin-right: 10px;
		}
    }

	.table-item{
		.table-item-action{
			&:first-child{
				margin-right: 10px;
			}
		}
	}

}