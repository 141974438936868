@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.srep-params-list-rt{
	@include componentDefaultFonts();

	.panel-list{
		>div{
			@include clearfix();
		}
		.panel-settings-actions{
			.actions{
				float: right;
				margin-right: 20px;
				margin-top: -40px;
                :global(.btn-rt){
                    &:first-child{
                        margin-right: 10px;
                    }
                }
			}
		}
		
	}
	.modal-new-Settings{
		
		:global(.modal-rt-container .modal-rt-body){
			overflow-y: unset;
		}
		:global(.modal-rt-body){
			min-height: 200px;
		}
		:global(.checkbox-switch-animate){
			&:before{
				z-index: 5;
			}
		}
	}
	.modal-delete-srep {
        text-align: center;

        label{
            font-size: 14px;
        }
		:global(.btn-rt){
			margin: 10px;
		}
    }
	
}