.point-limit-area-new-rt{
	.selectType{
		:global(.list-select-rt){
			z-index:1001;
		}
	}

	.actions{
		text-align: right;
        margin-top: 10px;
		.button-action{
			&:first-child{
				margin-right: 10px;
			}
		}
	}
}