@import '@optimuminterfaces/revex-react-components/dist/_mixins';
@import '@optimuminterfaces/revex-react-components/dist/_colors'; 

.point-event-rt{
    @include componentDefaultFonts();

    :global([class*="col"]){
        margin-bottom: 10px;
    }
    .point-event-data{
        @include componentDefaultFonts();
    
    }
    .point-event-feedback{
        :global(.text-information-subtext){
            line-height: 20px;
        }
    }

    .point-event-footer{
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}