@import '@optimuminterfaces/revex-react-components/dist/_mixins';
@import '@optimuminterfaces/revex-react-components/dist/_colors';

.daily-scale-hours-rt{
	@include componentDefaultFonts();
    @include clearfix();

    display: inline-flex;

    .daily-scale-time{
        margin-right: 5px
    }
}
