@import '@optimuminterfaces/revex-react-components/dist/_colors';
@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.import-file-list-rt{
	@include componentDefaultFonts();
	
	.import-file-list-rt-actions{
		@include clearfix();
		.button-rt-new-upload{
			float: right;
		}
	}

	.modal-add-import-file-actions{
		text-align: center;
		:global(.btn-rt){
			&:first-of-type{
				margin-right: 10px;
			}
		}
	}
	
}
