@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.point-incident-list-rt{
	:global(.message), :global(.table-rt){
		font-size: 12px;
	}

	.panel-list-justifications {

        // fix css revex
        :global(.btn-rt) {
            line-height: 0;
        }
    }

	.modal-justify{
		dl{
			@include clearfix();
			dt{
				float: left;
				clear: left;
				font-weight: bold;
				margin-right: 10px;
				//line-height: 22px;
				margin-bottom: 10px;
				width: 150px;
			}
			dd{
				margin-left: 0px;
				//line-height: 22px;
				text-align: left;
				padding-right: 20px;
				margin-bottom: 10px;

				:global(.time-rt){
					float: left;
					margin-right: 10px;
				}
			
			}
		}
	}
}