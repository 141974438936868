@import '@optimuminterfaces/revex-react-components/dist/_colors';
@import '@optimuminterfaces/revex-react-components/dist/_mixins';
@import '@optimuminterfaces/revex-react-components/dist/_variables';

.point-incident-my-new-rt {
    @include componentDefaultFonts();
    min-height: 500px;

    :global(.datetime-picker-content) {
        z-index: 11;
    }

    .loading-data {
        min-height: 80px;
        margin-top: 15px;
    }

    .point-incident-justification-form {
        position: relative;
        margin-top: -20px;

        .point-justification-container {
            @include clearfix();

            .points-justification {
                @include clearfix();
                float: left;
            }

        }

        .add-point-action {
            @include clearfix();
            text-align: center;

            .add-point {
                margin-top: 3px;
            }
        }
    }

    .justification-actions {
        text-align: center;
        margin-top: 10px;

        .justification-action {
            &:first-child {
                margin-right: 10px;
            }
        }
    }
}