@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.point-incident-justification-rt{
	.point-incident-justification-form{
		position: relative;

		.point-justification-container{
			@include clearfix();
			.points-justification{
				@include clearfix();
				float: left;
			}
		}

		.add-point-action{
			@include clearfix();
			text-align: center;
			.add-point{
				margin-top: 3px;
			}
		}
	}

	.justification-actions{
		text-align: center;
		margin-top: 10px;

		.justification-action{
			margin-right: 10px;
			&:last-child{
				margin-right: 0;
			}
		}
	}

	.modal-validate-justify-body{
		
	}

}
