@import '@optimuminterfaces/revex-react-components/dist/_colors';
@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.point-registration-event-change{
	@include componentDefaultFonts();
	.icon{
		text-align: center;
		margin-top: 20px;
		margin-bottom: 10px;
	}
	.point-registration-event-footer{
		text-align: center;
		margin-top: 15px;
	}
}