@import '@optimuminterfaces/revex-react-components/dist/_mixins';
@import '@optimuminterfaces/revex-react-components/dist/_colors';

.limit-areas-list-rt{
	@include componentDefaultFonts();

	.group-buttons{
		float: right
	}

	.modal-delete{
        text-align: center;
		.modal-delete-action{
			margin-right: 10px;
		}
    }

	.map-view-box{
		@include borderRadius(10px);
		margin-bottom: 20px;
		.map-view{
			:global(.map){
				@include borderRadiusTopLeft(10px);
				@include borderRadiusTopRight(10px);
			}
		}
		.no-location{
			@include borderRadiusTopLeft(10px);
			@include borderRadiusTopRight(10px);
			background-color: #f2f2f2;
			min-height: 140px;
			text-align: center;
			font-size: 50px;
			line-height: 140px;
		}
		.map-view-detalhes{
			color: #FFFFFF;
			border-top: 1px solid;
			padding: 10px;
			background-color: $primary-color;
			.title{
				font-weight: bold;
				font-size: 16px;
				margin-bottom: 10px;
				text-align: center;
			}
		}
		.map-view-actions{
			@include borderRadiusBottomLeft(10px);
			@include borderRadiusBottomRight(10px);
			padding: 10px;
			background-color: $primary-color-light;
			display: flex;
			flex-wrap: wrap;
			height: 40px;

			.button-action{
				flex-grow: 3;
				margin-right: 5px;
			}

		}
	}
}