@import '@optimuminterfaces/revex-react-components/dist/_colors';
@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.report-list-rt{
	@include componentDefaultFonts();
	
	.action{
		float: right;
		margin: 10px;
	}

	.alert-tiny-warning-msg-hbb-divergences{
		margin: 0 10px;
	}

	.modal-new-report{
		:global(.modal-new-rt-container .modal-new-rt-body){
			overflow-y: initial;
		}
		:global(.modal-new-rt-body){
			min-height: 200px;
		}

		:global(.list-select-rt){
			&:before{
				z-index: 5;
			}
		}
	}
}