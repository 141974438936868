@import '@optimuminterfaces/revex-react-components/dist/_colors';
@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.closed-periods-new-rt{
	@include componentDefaultFonts();
	
	.actions{
		text-align: right;
        margin-top: 10px;
		.button-action{
			&:first-child{
				margin-right: 10px;
			}
		}
	}

}