.table-list-pagination-rt{
	
	.actions{
		float: right;
		margin-bottom: 10px;
		>div{
			float: right;
		}
	}
}
