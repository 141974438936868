@import '@optimuminterfaces/revex-react-components/dist/_mixins';
@import '@optimuminterfaces/revex-react-components/dist/_colors';

.page-point-edit-rt{
    @include componentDefaultFonts();

    .form-point-edit{
		:global(.row){
			margin-bottom: 10px;
		}
		:global(.item-list-rt){
			text-transform: capitalize;
		}
        :global(.switch-rt){
            margin-bottom: 10px;
            margin-top: 10px;

            .checkbox-switch-text{
                font-size: 14px;
            }
        }
        .form-point-edit-actions{
            text-align: center;
            margin-top: 20px;
            :global(.btn-rt){
                &:first-child{
                    margin-right: 10px;
                }
            }
            
        }
        .point-category-title{
            color: $blue-color-light;
            border-bottom: 1px solid #e9e9e9;
            line-height: 40px;
            font-size: 18px;
            font-weight: bold;
            margin-top: 10px;    
            margin-bottom: 10px;    

        }
        .point-subcategory-title{
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 10px;
        }
        .tabs-active-content{
            margin-top: 15px;
        }
    }

    .modal-split-hours {
        :global(.modal-rt .modal-rt-container .modal-rt-body) {
            overflow-y: unset;
        }
    }

    .modal-confirm-recalc{
        text-align: center;

        label{
            font-size: 14px;
        }
    }

    .modal-employee-recalc{
        .dual-list-rt {
            margin: 10px;
        }

        .modal-actions{
            text-align: end;
            margin-top: 20px;
            .btn-rt{
                &:first-child{
                    margin-right: 10px;
                }
            }
        }
    }
}