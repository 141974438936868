@import "@optimuminterfaces/revex-react-components/dist/_mixins";
@import "@optimuminterfaces/revex-react-components/dist/_colors";

.hour-bank-balance-edit {
	@include componentDefaultFonts();
	.hour-month {
		display: flex;
		margin-bottom: 20px;
		> div {

			&:first-child {
				@include borderRadiusTopLeft(10px);
				@include borderRadiusBottomLeft(10px);
				cursor: pointer;
				text-align: center;
				font-size: 26px;
				line-height: 55px;
				
				width: 25px;
				background-color: $primary-color;
				color: #ffffff;
			}
		}
		:global(.datetime-picker-rt){
			flex: 1;
			text-align: center;
			background-color: $secondary-color-light;
			padding: 5px 5px;
		}
		
	}
	.actions{
		text-align: center;
		margin-top: 10px;
		:global(.btn-rt){
			&:first-child{
				margin-right: 10px;
			}
		}
		
	}
}
