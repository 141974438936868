@import '@optimuminterfaces/revex-react-components/dist/_colors';
@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.report-show-rt {
    @include componentDefaultFonts();

    .margin-0{
        margin: 0;
    }

    .dl-side-by-side{
        columns: 3;
    
        dt {
            font-weight: bold;
            margin-bottom: 5px;
        }

        dd {
            border: 1px solid $gray-color; 
            background-color: $gray-color;
            margin: 0 0 10px 0;
        }
    
        // dt {
        //     grid-column-start: 1;
        //     padding-right: 10px;
        //     display: inline;
        // }
    
        // dd {
        //     grid-column-start: 2;
        //     margin: 0;
        //     display: inline;
        //     line-break: anywhere;
        // }
    }

    table, th, td {
        border-top: 1px solid $gray-color;
        border-bottom: 1px solid $gray-color;
        border-collapse: collapse;
      }

}