@import '@optimuminterfaces/revex-react-components/dist/_colors';
@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.point-registration-my-list-rt{

	
	.point-registration-filters{
		:global(.btn-rt){
			margin-top: 22px;
		}
	}
	.point-registration-table{
		font-size: 12px;
		:global(.message-rt){
			font-size: 12px;
		}
	
		.point-mirror{
			font-size: 12px;
			padding-right: 5px;
			margin: 0px;

			dt{
				float: left;
				clear: left;
				font-weight: bold;
				margin-right: 10px;
				line-height: 16px;
				&:nth-of-type(odd){
					background-color: $gray-color;
				}
			}
			dd{
				margin-left: 0px;
				line-height: 16px;
				text-align: right;
				padding-right: 20px;
				&:nth-of-type(odd){
					background-color: $gray-color;
				}
			}
		}
		.point-window{
			margin-left: 15px;
		}
		.point-window-duration{
			margin-left: 25px;
		}
		.point-window-title{
			margin-left: 15px;
			font-size: 10px;
		}

		

		.point-realized-container{
			text-align: center;
			:global(.point-detail){
				float: left;
				margin-right: 5px;
			}
		}
	}
}