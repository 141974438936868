@import '@optimuminterfaces/revex-react-components/dist/_colors';
@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.point-registration-list{
    @include componentDefaultFonts();
	/*:global(.modal-rt){
		:global(.modal-rt-body){
			overflow: visible;
		}
		
	}*/
	.sub-title{
		margin-left: 10px;
	}
	.point-registration-filters{
		:global(.btn-rt){
			margin-top: 22px;
		}
	}
	.legend{
		margin: 10px;
		span{
			margin-left: 10px;
		}
	}
	.point-actions{
		margin-right: 20px;
		margin-top: -42px;
		// padding: 10px;
		>div{
			@include clearfix();
			float: right;
			margin-right: 10px;
			margin-bottom: 10px;

		}
		:global(.dropdown){
			text-align: left;
		}
	}
	.journey{
		color: $primary-color;
	}
	.late-work,.late{
		color: $red-color;
		font-weight: 700;
	}

	.overtime{
		color: $green-color;
		font-weight: 700;
	}

	.point-registration-resume-table{
		text-align: center;
		@media(max-width: 1175px){
			display: block;
		}
	}

	.point-registration-table{
		font-size: 12px;
		padding-bottom: 0px;
		:global(.message-rt){
			font-size: 12px;
		}
		.summary{
			:global(.avatar-name-value-text){
				font-size: 14px;
				div{
					text-overflow: ellipsis;
					overflow: hidden;
				}

				span{
					font-size: 10px;
				}
			}
			:global(.tag){
				font-size: 12px;
				@include boxShadow(0px 0px);
			}
			:global(.avatar-name-value-photo){
				float: none;
			}
			:global(.avatar-name-value-text){
				float: none;
				>div{
					color: #ffffff;
				}
			}
			dt{
				float: left;
				clear: left;
				font-weight: bold;
				padding-right: 1%;
				line-height: 28px;
				width: 200px;
				text-align: left;
			}
		
			dd{
				margin-left: 0px;
				line-height: 28px;
				font-weight: 300;
				//text-align: right;
				text-align: left;
				padding-right: 2%;
		
			}
		}


        thead{
			position: sticky;
			top: 0;
			z-index: 2;
            th:first-child {
                text-align: center;
            }
		}

		@media(max-width: 750px){
			display: block;
		}

		
        tbody{
            tr{
                height: 138px;
                td:first-child{
                    text-align: center;
                }
            }
        }

        .date{
            div{
                &:first-of-type{
                    font-weight: bold;
                    font-size: 14px;
                }
                font-size: 10px;
            }
        }
		
		.point-window{
			margin-left: 15px;
		}
		
		.point-window-duration{
			margin-left: 25px;
		}
		
		.point-window-title{
			margin-left: 15px;
			font-size: 10px;
		}

		.point-realized-container{
			text-align: center;
		}

        .point-actions{
            width: 5%;
        }
	}

    .point-mirror{
        font-size: 12px;
        padding-right: 5px;
        margin: 0px;
        min-width: 200px;

        dt{
            float: left;
            clear: left;
            font-weight: bold;
            padding-right: 1%;
            line-height: 16px;
            // &:nth-of-type(odd){
            // 	background-color: $gray-color;
            // }
        }

        dd{
            margin-left: 0px;
            line-height: 16px;
            text-align: right;
            padding-right: 2%;
            // &:nth-of-type(odd){
            // 	background-color: $gray-color;
            // }
        }

        &.striped{
            dt{
                 &:nth-of-type(odd){
                    background-color: $gray-color;
                }
            }
            dd{
                &:nth-of-type(odd){
                    background-color: $gray-color;
                }
            }
        }
    }

	.modal-footer-rt{
		border-top: 1px solid #e5e5e5;
		text-align: right;
		margin-top: 10px;
		padding-top: 10px;
	}
    .modal-edit-point{
        
        :global(.modal-new-rt-body){
            position: relative;
            padding: 0px;
        }
        
    }

    .modal-point-registration-event-new{
		
		:global(.modal-new-rt-container .modal-new-rt-body){
			overflow-y: unset;
		}
	}
	.modal-point-registration-detail-all-dates{
		:global(.modal-new-rt-container){
			width: 98%;
  			margin-left: calc(-98%/2);
			:global(.modal-new-rt-body){
				padding: 0px;
			}
		}
	}

	.point-registrion-events-container{
		.point-registrion-events-item{
			@include clearfix();
			div{
				float: left;
				height: 18px;
			}
			.point-registrion-events-item-1{
				width: 80%;
				@include clearfix();
			}
			.point-registrion-events-item-2{
				width: 18px;
				@include clearfix();
			}
			:global(.btn-rt){
				display: none;
				padding: 0;
				margin: 0;
				border: none;
			}

			&:hover{
				:global(.btn-rt){
					display: block;
					
				}
			}

		}
	}
	.modal-point-registration-event-change{
		:global(.modal-new-rt-container .modal-new-rt-body){
			overflow-y: unset;
		}
	}
	
}