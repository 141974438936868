.hour-bank-balance-list-rt{
    .group-buttons{
        float: right;
    }

	:global(.avatar-name){
        cursor: pointer;
    }
    :global(.table-rt){
        thead{
            th{
                white-space: pre-wrap;
                text-align: center;
            }
        }
        tbody{
            td{
                &:not(:first-child){
                    text-align: center;
                }
            }
        }
    }
}