@import '@optimuminterfaces/revex-react-components/dist/_colors';
@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.point-registration-edit{
    @include componentDefaultFonts();

	margin-bottom: 30px;
	.table-edit-points{
        thead{
            position: sticky;
            top: 0;
            z-index: 2;

            tr{
                &:first-of-type{
                    background-color: #ffffff;
                    height: 80px; 
                    :global(.btn-rt){
                        margin-bottom: 0px;
                    }
                }
                th{
                    text-align: center;
                    padding: 0px;
					
                    &:nth-child(4){
                        width: 70%;
                    }
                    &:nth-child(5){
                        width: 15%;
                    }
                }
            }

        }
    
        tbody{
            tr{
                height: 61px;
            }

            td{
                margin: 0px;
				min-height: 255px;
                >div{
                    float: left;
                  
                }

				:global(.tag){
					font-weight: bold;
					  font-size: 10px;
					  text-shadow: 1px 1px #696969;
				}

                .point-edit-action{

                }
                .add-point{
                    display: unset;
                    margin-top: 3px;
                }

                &:hover{
                    .add-point{
                        display: unset;
                    }
                }
                &.date{

                    div{
                        text-align: center;
                        float: none;
                        &:first-of-type{
                            font-weight: bold;
                            font-size: 14px;
                        }
                        font-size: 10px;
                    }
                }

				&.status{
					text-align: center;
				}
            }
            :global(.datetime-picker-rt){
                //margin-top: 5px;
            }
			:global(.drag-drop-item-container){
				min-width: 145px;
			}
        }
	}

	// .point-edit-actions{
	// 	text-align: right;
    //     background-color: $primary-color-dark;
	// 	button{
	// 		margin-right: 5px
	// 	}
	// 	&.fixed{
	// 		position: fixed;
	// 		overflow: hidden;
	// 		z-index: 1;
	// 		height: 50px;
	// 		width: 74%;
	// 		text-align: center;
	// 		background-color: #ffffff;

	// 		.point-edit-action{
	// 			margin-top: 10px;
	// 		}
	// 	}
		
	// }

}