@import '@optimuminterfaces/revex-react-components/dist/_colors';
@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.point-calculate-details-calculate{
	@include componentDefaultFonts();

	.point-registration-details-calculate-table{
		@include componentDefaultFonts();
		text-align: center;
		font-size: 10px;
		border: 0px;
		:global(.header-table){
			position: sticky;
            top: 0;
            z-index: 2;
			th{
				white-space: pre-wrap;
				text-align: center;
				padding: 0px 2px;
			}
		}
		tbody{
			font-size: 12px;
			tr{
				td{
					padding-left: 0px;
					&:first-of-type{
						padding-left: 2px;
					}
				}
			}
		}
		.date{
            div{
                &:first-of-type{
                    font-weight: bold;
                    font-size: 12px;
                }
                font-size: 10px;
            }
        }
		.journey{
			color: $primary-color;
		}
		.late{
			color: $red-color;
			font-weight: 700;
		}
		.overtime{
			color: $green-color;
			font-weight: 700;
		}
		.compensation{
			font-weight: 700;
		}

		.resumeDatabase{
			
			&.fixed{
				position: sticky;
				top: 55px;
				z-index: 3;
			}
			td{
				background-color: $primary-color-dark !important;
				color: #ffffff;
				font-weight: bold;
			}
		}
	}
}