@import '@optimuminterfaces/revex-react-components/dist/_colors';
@import '@optimuminterfaces/revex-react-components/dist/_mixins';

.point-registration-detail{
	@include componentDefaultFonts();
	@include borderRadius(5px);
	width: 120px;
	border: 1px solid $primary-color;
	.mapview-container{
		height: 80px;
		:global(.map){
			@include borderRadiusTopLeft(5px);
			@include borderRadiusTopRight(5px);
		}
		.no-location{
			font-size: 50px;
			line-height: 80px;
			text-align: center;
			color: #e9e9e9;
			position: relative;
			&:after{
				content: 'Ponto sem informações geográfica';
				position: absolute;
				font-size: 10px;
				line-height: normal;
				color: $primary-color;
				left: 0;
				margin-left: 3px;
				margin-top: -10px;
				top: 50%;
			}
		}
	}
	.details{
		background-color: $primary-color;
		border-top: 1px solid $primary-color;
		text-align: center;
		color: #ffffff;
		
		.time{
			font-weight: bold;
		}
		.more{
			padding: 6px 0px;
			font-size: 10px;
			
		}
	}
	

}