.list-srep-configurations-rt{

    :global(.pagination-compact-rt){
        float: right;
		margin-bottom: 10px;
    }
    
    :global(.per-page-rt){
        float: right;
    }
	.btn-action{
		margin-right: 10px;
	}

    th,td{
        text-align: center;
    }
}